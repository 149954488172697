import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { NoseWorkDmCandidate, NoseWorkDmCandidateResponse } from '../../../models.generated/nosework';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-nosework-dm-candidates',
  templateUrl: './nosework-dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class NoseWorkDmCandidatesComponent implements OnInit {
  noseWorkDmCandidateResponse: NoseWorkDmCandidateResponse;
  noseWorkDmCandidates: NoseWorkDmCandidate[];
  regionSelectItems = new RegionHelpers().getRegionSelectItems(true);
  regionSelectedValue = this.regionSelectItems[0].value;
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$().subscribe(a => {
      this.noseWorkDmCandidateResponse = a;
      this.noseWorkDmCandidates = a.candidates;
    });
  }

  selectionChangeHandler(): void {
  }

  private fetchData$(): Observable<NoseWorkDmCandidateResponse> {
    const url = 'api/NoseWorkData/GetNoseWorkDmCandidates';
    return this.httpDataService.getData<NoseWorkDmCandidateResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NW;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';
    // dataTable.detailsComponent = LydighedDmCandidatesDetailsComponent;

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Udtaget', field: 'dchEkvipage.ekvipageNote' }
    ]

    return dataTable;
  }
}
