<div *ngIf="noseWorkDmCandidateResponse">
  <div class="pageHeader">
    <h1>Udtagelseplaceringer til DM</h1>

    <div class="flex-container wrap">
      <app-dch-select style="width: 350px" [(selectedValue)]="regionSelectedValue" [selectItems]="regionSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    </div>

    <h4>
      Udtagelsesperiode: {{noseWorkDmCandidateResponse.startDate | dcHFullDate: noseWorkDmCandidateResponse.endDate}}
    </h4>
  </div>

  <div class="pageBody">
    <div class="noDataToShow">
      Her kommer snart dm kandidater
    </div>

    <!--<app-dch-data-table [dataTable]="dataTableDef" [dataSource]="noseWorkDmCandidates"></app-dch-data-table>-->
  </div>
</div>
