import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { DmUdtagAgility, DmUdtagIgpFh, DmUdtagIpg3, DmUdtagLydighed, DmUdtagNordisk, DmUdtagRally, UdtagETur } from '../../../models.generated/dchEnums';
import { DcHEkvipageResponse, DcHStamData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { HttpDataService } from '../../../services/http-data.service';
import { AdminStamDataHelpers } from './stam-data.component.helpers';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';

@Component({
  selector: 'app-admin-stam-data',
  templateUrl: './stam-data.component.html',
  styleUrls: ['./stam-data.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, ReactiveFormsModule, DcHDataTableComponent, DcHSelectComponent]
})
export class AdminStamDataComponent implements OnInit {
  private adminStamDataHelpers = new AdminStamDataHelpers();
  agilitySelectItems = this.adminStamDataHelpers.getAgilitySelectItems();
  igpFhSelectItems = this.adminStamDataHelpers.getIgpFhSelectItems();
  lydighedSelectItems = this.adminStamDataHelpers.getLydighedSelectItems();
  rallySelectItems = this.adminStamDataHelpers.getRallySelectItems();
  eturSelectItems = this.adminStamDataHelpers.getEturSelectItems();
  nordiskSelectItems = this.adminStamDataHelpers.getNordiskSelectItems();
  igp3SelectItems = this.adminStamDataHelpers.getIgp3SelectItems();
  udtagAgilityFormControl: FormControl<DmUdtagAgility>;
  udtagLydighedFormControl: FormControl<DmUdtagLydighed>;
  udtagRallyFormControl: FormControl<DmUdtagRally>;
  udtagETurFormControl: FormControl<UdtagETur>;
  udtagNordiskFormControl: FormControl<DmUdtagNordisk>;
  udtagIpg3FormControl: FormControl<DmUdtagIpg3>;
  udtagIgpFhFormControl: FormControl<DmUdtagIgpFh>;
  ekvipageNoteFormControl: FormControl<string>;

  dataTableDef = this.getDcHDataTable();
  stamDatas = new Array<DcHStamData>();
  searchText = '';
  disableSaveButton = true;

  private stamDataFormGroup: FormGroup;
  private prevProfileId = -1;
  private searchTerms: Subject<string> = new Subject();

  constructor(readonly httpDataService: HttpDataService, private formBuilder: FormBuilder, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {

    this.getData().pipe(
      tap(a => {
        this.stamDatas = a;
      }))
      .subscribe();
    this.searchTerms.next('-dmudtag');
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  onFormSubmit(dcHEkvipage: DcHStamData): void {
    dcHEkvipage.dmUdtagAgility = this.udtagAgilityFormControl.value;
    dcHEkvipage.dmUdtagRally = this.udtagRallyFormControl.value;
    dcHEkvipage.dmUdtagLydighed = this.udtagLydighedFormControl.value;
    dcHEkvipage.udtagETur = this.udtagETurFormControl.value;
    dcHEkvipage.dmUdtagNordisk = this.udtagNordiskFormControl.value;
    dcHEkvipage.dmUdtagIpg3 = this.udtagIpg3FormControl.value;
    dcHEkvipage.dmUdtagIgpFh = this.udtagIgpFhFormControl.value;
    dcHEkvipage.ekvipageNote = this.ekvipageNoteFormControl.value;

    const msg = dcHEkvipage.hundeforer + ' m. ' + dcHEkvipage.hund;

    this.dialogService.showDialog([msg, 'Gem ændringer'], 'Admin stamdata', 'OkCancel').subscribe(a => {
      if (a) {
        const url = 'api/StamData/UpdateStamData';
        this.httpDataService.postData<DcHStamData, DcHStamData>(url, dcHEkvipage).subscribe(() => this.disableSaveButton = true);
      }
    });
  }

  private getDcHDataTable(): DcHDataTable {
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen stamdata, brug søge funktion';
    dataTable.autoCloseDetails = true;

    dataTable.columns = [
      { headerText: '', field: 'edit', dataType: 'rowCommand', width: '15px' },
      { headerText: '', field: 'klubModulStatus', dataType: 'klubModulStatus', textalign: 'center', width: '15px' },
      { headerText: 'MdlNr', field: 'profileId', textalign: 'center' },
      { headerText: 'Ekvipage', field: '', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'forening' }
    ]

    return dataTable;
  }

  private getData(): Observable<DcHStamData[]> {
    return this.searchTerms
      .pipe(
        filter(text => text.length > 2),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.fetchData(term))
      );
  }

  private fetchData(term: string): Observable<DcHStamData[]> {
    const url = 'api/StamData/GetStamDatasBySearchText';
    const params = new HttpParams().set('searchTerm', term);
    return this.httpDataService.getDataWithParm<DcHEkvipageResponse>(url, params)
      .pipe(map(a => a.ekvipages));
  }

  getStamDataFormGroup(dcHEkvipage: DcHStamData): FormGroup {

    if (dcHEkvipage.dogProfileId === this.prevProfileId) {
      return this.stamDataFormGroup;
    }

    this.prevProfileId = dcHEkvipage.dogProfileId;
    this.udtagAgilityFormControl = new FormControl(dcHEkvipage.dmUdtagAgility as DmUdtagAgility, { nonNullable: true });
    this.udtagLydighedFormControl = new FormControl(dcHEkvipage.dmUdtagLydighed as DmUdtagLydighed, { nonNullable: true });
    this.udtagRallyFormControl = new FormControl(dcHEkvipage.dmUdtagRally as DmUdtagRally, { nonNullable: true });
    this.udtagETurFormControl = new FormControl(dcHEkvipage.udtagETur as UdtagETur, { nonNullable: true });
    this.udtagNordiskFormControl = new FormControl(dcHEkvipage.dmUdtagNordisk as DmUdtagNordisk, { nonNullable: true });
    this.udtagIpg3FormControl = new FormControl(dcHEkvipage.dmUdtagIpg3 as DmUdtagIpg3, { nonNullable: true });
    this.udtagIgpFhFormControl = new FormControl(dcHEkvipage.dmUdtagIgpFh as DmUdtagIgpFh, { nonNullable: true });
    this.ekvipageNoteFormControl = new FormControl(dcHEkvipage.ekvipageNote, { nonNullable: true });

    this.stamDataFormGroup = this.formBuilder.group({
      dmUdtagAgility: this.udtagAgilityFormControl,
      dmUdtagLydighed: this.udtagLydighedFormControl,
      dmUdtagRally: this.udtagRallyFormControl,
      udtagETur: this.udtagETurFormControl,
      dmUdtagNordisk: this.udtagNordiskFormControl,
      dmUdtagIpg3: this.udtagIpg3FormControl,
      dmUdtagIgpFh: this.udtagIgpFhFormControl,
      ekvipageNote: this.ekvipageNoteFormControl
    });

    this.disableSaveButton = true;
    this.stamDataFormGroup.valueChanges.subscribe(() => { this.disableSaveButton = false });
    return this.stamDataFormGroup;
  }
}
