// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.2
//   protoc               v4.24.4
// source: dchEnums.proto

/* eslint-disable */

export const protobufPackage = "DcHWebResProtobuf.enums";

export enum DcHNoseWorkKlasseMoment {
  DcHNoseWorkKlasseMomentNone = 0,
  NW1Total = 1,
  NW1Moment1 = 2,
  NW1Moment2 = 3,
  NW1Moment3 = 4,
  NW1Moment4 = 5,
  NW2Total = 6,
  NW2Moment1 = 7,
  NW2Moment2 = 8,
  NW2Moment3 = 9,
  NW2Moment4 = 10,
  NW3Total = 11,
  NW3Moment1 = 12,
  NW3Moment2 = 13,
  NW3Moment3 = 14,
  NW3Moment4 = 15,
  NWEliteTotal = 16,
  NWEliteMoment1 = 17,
  NWEliteMoment2 = 18,
  NWEliteMoment3 = 19,
  NWEliteMoment4 = 20,
  NWSeniorTotal = 21,
  NWSeniorMoment1 = 22,
  NWSeniorMoment2 = 23,
  NWSeniorMoment3 = 24,
  NWSeniorMoment4 = 25,
  NWOpenTotal = 26,
  NWOpenMoment1 = 27,
  NWOpenMoment2 = 28,
  NWOpenMoment3 = 29,
  NWOpenMoment4 = 30,
  UNRECOGNIZED = -1,
}

export enum DcHNoseWorkKlasse {
  DcHNoseWorkKlasseNone = 0,
  NW1 = 1,
  NW2 = 2,
  NW3 = 3,
  NWElite = 4,
  NWSenior = 5,
  NWOpen = 6,
  UNRECOGNIZED = -1,
}

export enum DcHBrugsKlasse {
  DcHBrugsKlasseNone = 0,
  IGP1 = 1,
  IGP2 = 2,
  IGP3 = 3,
  IGPFH = 4,
  IFH1 = 5,
  IFH2 = 6,
  IFHV = 7,
  BH = 8,
  AD = 9,
  BegynderB = 10,
  BegynderAB = 11,
  IBGH1 = 12,
  IBGH2 = 13,
  IBGH3 = 14,
  FPr1 = 15,
  FPr2 = 16,
  FPr3 = 17,
  UPr1 = 18,
  UPr2 = 19,
  UPr3 = 20,
  SPr1 = 21,
  SPr2 = 22,
  SPr3 = 23,
  StPr1 = 24,
  StPr2 = 25,
  StPr3 = 26,
  UNRECOGNIZED = -1,
}

export enum DcHNordiskKlasse {
  DcHNordiskKlasseNone = 0,
  LilleNordiskSpor = 1,
  LilleNordiskRunder = 2,
  NordiskSpor = 3,
  NordiskRunder = 4,
  NordiskRapportering = 5,
  NordiskSkydd = 6,
  UNRECOGNIZED = -1,
}

export enum DcHRallyKlasse {
  DcHRallyKlasseNone = 0,
  Beginner = 1,
  Practiced = 2,
  Expert = 3,
  Champion = 4,
  Open = 5,
  DM1Run1 = 100,
  DM1Run2 = 101,
  DM1Sum = 105,
  DMFinale = 200,
  DMTotal = 205,
  UNRECOGNIZED = -1,
}

export enum DcHLydighedKlasse {
  DcHLydighedKlasseNone = 0,
  A = 1,
  B = 2,
  C = 3,
  E = 4,
  L1 = 5,
  L2 = 6,
  UNRECOGNIZED = -1,
}

export enum DcHKlasseType {
  DcHKlasseTypeNone = 0,
  Agility3Year = 1,
  AgilitySenYear = 2,
  LydABCE = 3,
  LydIPG3 = 4,
  LydIGPFH = 5,
  LydETurUdtag = 6,
  RallyKlasse = 7,
  AgilityKlasse = 8,
  NordiskKlasse = 9,
  LydCOprykkere = 10,
  NoseWorkKlasse = 11,
  UNRECOGNIZED = -1,
}

export enum KonkurrenceType {
  None = 0,
  AG = 1,
  LY = 2,
  RA = 3,
  NO = 4,
  BR = 5,
  NW = 6,
  HO = 7,
  UNRECOGNIZED = -1,
}

export enum NordiskGodkendt {
  IG = 0,
  G = 1,
  G1 = 2,
  G2 = 3,
  UNRECOGNIZED = -1,
}

export enum IPODSB {
  IPODSBNone = 0,
  UDP = 1,
  TS = 2,
  UTS = 3,
  UNRECOGNIZED = -1,
}

export enum LiveDataStatus {
  Ok = 0,
  NotActive = 1,
  UnknownCompetition = 2,
  UNRECOGNIZED = -1,
}

export enum DcHKonkurrenceArt {
  DcHKonkurrenceArtNone = 0,
  Lokal = 1,
  Kreds = 2,
  Lands = 3,
  DM = 4,
  Eliteturnering = 5,
  JM = 6,
  NordiskMesterskab = 7,
  BHU = 8,
  UNRECOGNIZED = -1,
}

export enum DcHKreds {
  DcHLands = 0,
  Kreds1 = 1,
  Kreds2 = 2,
  Kreds3 = 3,
  Kreds4 = 4,
  Kreds5 = 5,
  Kreds6 = 6,
  UNRECOGNIZED = -1,
}

export enum KlubmodulStatus {
  KlubmodulStatusNone = 0,
  Member = 1,
  NotMember = 2,
  Deleted = 3,
  UNRECOGNIZED = -1,
}

export enum AgilitySize {
  AgilitySizeNone = 0,
  Lille = 1,
  Mellem = 2,
  Stor = 3,
  Intermediate = 4,
  UNRECOGNIZED = -1,
}

export enum HoopersSize {
  HoopersSizeNone = 0,
  HoopersSizeSmall = 1,
  HoopersSizeLarge = 2,
  UNRECOGNIZED = -1,
}

export enum RallyHeight {
  RallyHeightNone = 0,
  H15 = 15,
  H25 = 25,
  H40 = 40,
  UNRECOGNIZED = -1,
}

export enum AgilityAlder {
  Adult = 0,
  Junior = 1,
  Senior = 2,
  UNRECOGNIZED = -1,
}

export enum HoopersAlder {
  HoopersAlderAdult = 0,
  HoopersAlderJunior = 1,
  HoopersAlderSenior = 2,
  UNRECOGNIZED = -1,
}

export enum DmUdtagAgility {
  DmUdtagAgilityAuto = 0,
  DmUdtagAgilityIkke = -1,
  InviteAsSmall = 100,
  InviteAsMedium = 101,
  InviteAsIntermediate = 102,
  InviteAsLarge = 103,
}

export enum DmUdtagLydighed {
  DmUdtagLydighedAuto = 0,
  DmUdtagLydighedIkke = -1,
  UdtagetLydighedKreds = 1,
  UdtagetLydighedLands = 2,
  InviteAsC = 100,
  InviteAsB = 101,
  InviteAsA = 102,
  InviteAsE = 103,
  UdtagetLydighedReserve = 200,
  UdtagetLydighedListe = 300,
}

export enum DmUdtagRally {
  DmUdtagRallyAuto = 0,
  DmUdtagRallyIkke = -1,
  UdtagetRallyKreds = 1,
  UdtagetRallyLands = 2,
  InviteAsLilleVeteran = 100,
  InviteAsMellemStor = 101,
  UdtagetRallyReserve = 200,
  UdtagetRallyListe = 300,
}

export enum UdtagETur {
  UdtagETurAuto = 0,
  UdtagETurIkke = -1,
  InviteAsETur = 100,
}

export enum DmUdtagNordisk {
  DmUdtagNordiskAuto = 0,
  DmUdtagNordiskIkke = -1,
  UdtagetNordiskKreds = 1,
  UdtagetNordiskLands = 2,
  InviteAsSpor = 100,
  InviteAsRunder = 101,
  UdtagetNordiskReserve = 200,
}

export enum DmUdtagIpg3 {
  DmUdtagIpg3Auto = 0,
  DmUdtagIpg3Ikke = -1,
  UdtagetIgp3Lands = 2,
  InviteAsIpg3 = 100,
  UdtagetIgp3Reserve = 200,
}

export enum DmUdtagIgpFh {
  DmUdtagIgpFhAuto = 0,
  DmUdtagIgpFhIkke = -1,
  UdtagetIgpFhLands = 2,
  InviteAsIgpFh = 100,
  UdtagetIgpFhReserve = 200,
}

export enum DmUdtagNoseWork {
  DmUdtagNoseWorkAuto = 0,
  DmUdtagNoseWorkIkke = -1,
  InviteAsNoseWork = 100,
  UdtagetNoseWorkReserve = 200,
}

export enum HoopersKlasse {
  HoopersKlasseNone = 0,
  BeginnerRun1 = 1,
  BeginnerRun2 = 2,
  BeginnerRun3 = 3,
  BeginnerRun4 = 4,
  BeginnerRun5 = 5,
  BeginnerRun6 = 6,
  PracticedRun1 = 7,
  PracticedRun2 = 8,
  PracticedRun3 = 9,
  PracticedRun4 = 10,
  PracticedRun5 = 11,
  PracticedRun6 = 12,
  ExpertRun1 = 13,
  ExpertRun2 = 14,
  ExpertRun3 = 15,
  ExpertRun4 = 16,
  ExpertRun5 = 17,
  ExpertRun6 = 18,
  ChampionRun1 = 19,
  ChampionRun2 = 20,
  ChampionRun3 = 21,
  ChampionRun4 = 22,
  ChampionRun5 = 23,
  ChampionRun6 = 24,
  OpenRun1 = 25,
  OpenRun2 = 26,
  UNRECOGNIZED = -1,
}
